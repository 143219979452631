export const client: RECO.Mobile.Config.Client = (window["client"] = {
    title: "瑞谷云企-产业服务运营商",
    techSupport: "技术支持:  上海瑞谷拜特软件技术有限公司",
    urlArgs: "v=180427",
    tel: "021-20231080",
    /** 是否在Header里调用查询工单的接口 */
    isgetOrderOnHeader: true,
    parkCode: "ZCKJ20170707135129",
    /**
     * 园小二代替名称
     */
    parkhelperText: "瑞谷云企",
    ...window["client"],
});

export const server: RECO.Mobile.Config.Server = (window["server"] = {
    apiKey: {
        apiKey: "Bitech\\PC",
        secret: "44678314ba0efa0c",
    },
    apiVersion: 9,
    auth: {
        oauth2Url: "authorization/oauth2",
        autoLogin: !0,
        autoRefreshToken: !0,
        anonymousLogin: !0,
    },
    rsa: {
        enable: true,
        publicKey: `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDXu1ms9TrhrMlIn3iv4g2Pw9Mfr9abdAB-TpDD65G98wjHZxgkZfhzUHE8BSsUDzIHfOiCd4RT5fZtTiop9bzwecgYCrAICjrVC-8ZWKf1RqRX4EXiISyEBHb4YAER_Rt2TQEWGPCi14ujDTA9qr1_YRUFfh52nrc-MfkfC12BwQIDAQAB`,
    },
    webappurl: "//linux.bitechdevelop.com/reco.ipark.10.0.api/", // 后台地址
    appdownURL: "http://demo.bitech.cn/iparkappjavaalpha/", // app下载地址
    // assetsUrl: "http://192.168.40.164:8888/", // 图片存放地址，用于富文本中获取图片
    jsonURL: "http://dev.bitechdevelop.com/reco.ipark.cms/webSeo.json", // seo生成json文件路径

    // assetsUrl: "https://fat.bitechdevelop.com/parkhelper-mobileapi/", // 图片存放地址，用于富文本中获取图片
    // url: "https://fat.bitechdevelop.com/parkhelper-mobileapi/", // 测试接口
    assetsUrl: "https://i.bitech.cn/serparkservicemall-mobileapi/", // 图片存放地址，用于富文本中获取图片
    url: "https://i.bitech.cn/serparkservicemall-mobileapi/", // 测试接口
    // url: "http://192.168.40.164:8888/", // 正在使用
    // url: "http://192.168.30.124:8082/", // 本地
    // url: "http://192.168.30.239:8080/", // 本地

    ...window["server"],
});
